var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"validationObserver"},[_c('form',[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"companyCopy.name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name *","name":"name","outlined":"","error-messages":errors},model:{value:(_vm.companyCopy.name),callback:function ($$v) {_vm.$set(_vm.companyCopy, "name", $$v)},expression:"companyCopy.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"companyCopy.personalIdentificationNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Personal identification number *","name":"personalIdentificationNumber","outlined":"","error-messages":errors},model:{value:(_vm.companyCopy.personalIdentificationNumber),callback:function ($$v) {_vm.$set(_vm.companyCopy, "personalIdentificationNumber", $$v)},expression:"companyCopy.personalIdentificationNumber"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"companyCopy.email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Company Email","name":"email","outlined":"","error-messages":errors},model:{value:(_vm.companyCopy.email),callback:function ($$v) {_vm.$set(_vm.companyCopy, "email", $$v)},expression:"companyCopy.email"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","item-value":"id","return-object":"","outlined":"","items":_vm.countries,"error-messages":errors},model:{value:(_vm.companyCopy.address.country),callback:function ($$v) {_vm.$set(_vm.companyCopy.address, "country", $$v)},expression:"companyCopy.address.country"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"companyCopy.email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Address *","name":"address","outlined":"","error-messages":errors},model:{value:(_vm.companyCopy.address.name),callback:function ($$v) {_vm.$set(_vm.companyCopy.address, "name", $$v)},expression:"companyCopy.address.name"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.handleClickCancelButton}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.handleClickSaveButton}},[_vm._v(" Save ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }