




































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import cloneDeep from 'lodash.clonedeep';
import { namespace } from 'vuex-class';
import CountryStoreGetters from '@/constants/store/modules/country/getters';
import Country from '@/typings/backend/jsonApiTranslations/country';
import Company from '../../typings/backend/jsonApiTranslations/company';

const countryStoreModule = namespace('countries');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EditCompanyComponent extends Vue {
  @countryStoreModule.Getter(CountryStoreGetters.GET_COUNTRIES) countries!: Country[];

  @Prop({ required: true })
  company!: Company;

  @Prop({ default: false, type: Boolean })
  loading!: boolean;

  @Watch('company')
  public watchCompany(): void {
    this.companyCopy = cloneDeep(this.company);
  }

  public companyCopy: Company = cloneDeep(this.company);

  public handleClickSaveButton(): void {
    this.$emit('company:edit', { company: this.companyCopy });
  }

  public handleClickCancelButton(): void {
    this.$emit('cancel:click');
  }
}
